* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
  font-family: 'Inter', sans-serif !important;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');
.t_active{
  width: 480px !important;
}
.admin-auth {
  height: 100vh;
}
.pre_ren{
  overflow-x: hidden;
  padding: 0px 10px;
}
.try {
  color: #a1a5b7;
}

.symbol:hover #slink {
  display: block !important;
}

.card-flush1 {
  height: 120px !important;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px !important;
}
.fst{
  font-size: 28px !important;
}
.btn-group {
  margin-top: 20px;
}

.btn-group a {
  margin: 0px 12px;
  font-size: 18px;
  text-decoration: underline;
  color: #000000;
}

.page-heading1 {
  font-size: 28px !important;
  font-weight: bold !important;
  font-family: 'Inter', sans-serif !important;
  color: #0B0E1E !important;
}

.btn-thin {
  padding: 12px 18px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
}

.active_project {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-title1 {
  width: 100% !important;
}

.form_search input {
  font-weight: 500;
  color: #919EAB;
  font-size: 14px;
  outline: none;
  border: 1px solid #DFE3E8 !important;
  width: 282px !important;
  max-width: 100%;
  height: 42px !important;
}

.name44 {
  color: #1E293B !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  text-transform: capitalize !important;

}

.name58 {
  width: 30%;
  max-width: 100%;
}

.tri11 td {
  color: #0F172A !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}

.status-baring {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 14px 16px;
  /* gap: 10px; */
  width: 118px;
  height: 44px;
  background: #ffffff;
  border: 1px solid #e1e3ea;
  border-radius: 6px;
}

.status-baring .reding {
  width: 8px;
  height: 8px;
  background-color: #ff5449;
  border-radius: 50%;
}

.status-baring .green {
  width: 8px;
  height: 8px;
  background-color: #49ff55;
  border-radius: 50%;
}

.act {
  width: 8px;
  height: 8px;
  background-color: #49ff55;
  border-radius: 50%;
}

.inact {
  width: 8px;
  height: 8px;
  background-color: #ff5449;
  border-radius: 50%;
}

.ting {
  display: flex;
  align-items: center;
}

.status-baring p {
  margin-top: 12px;
}



.edit_y {
  margin-left: 10px;
}

.delete_y {
  margin-left: 10px;
}
.view_all{
  margin-top: 30px !important;
}
.view_all p {
  text-align: center;
  font-weight: 500;
  color: #3595F6;
  font-size: 14px;
}

.tyu {
  margin-left: 10px;
}

.tyu1 {
  color: #7E8299;
  font-size: 17px;
  font-weight: 400;
  margin-left: 10px;
}

.input-user label {
  color: #3F4254;
  font-weight: 400;
  font-size: 15px;
}

.input-user input {
  height: 64px;
  border: 1px solid #D9E0E6;
  color: #BFC8D1;
  font-weight: 400;
  font-size: 15px;
}

.dropzone {
  margin-top: 15px !important;
}

#editor {
  height: 260px;
  resize: none;
}

.form-check-input {
  width: 40px !important;
  height: 24px !important;
}

.lbl-backImage {
  background: #D7EAFD;
  border: 1px dashed #3595F6;
  border-radius: 6px;
  padding: 30px 10px;
  position: relative;
  width: 213px;
  height: 151px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 20px;
}

.drawer-overlay {
  background-color: #5E6A82B2 !important;
}

.lbl-backImage span.read-btn {
  width: 89px;
  height: 37px;
  background: #FFFFFF;
  border-radius: 4px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #A1A5B7;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.lbl-backImage span.content {
  display: block;
  margin-top: 15px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: #2A78C5;
}
.uuii{
  width: 50px !important;
  height: 50px !important;
  object-fit: cover !important;
}
.uif{
  width: 10px !important;
  height: 10px !important;
}
.lbl-backImage .backImage {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.mediaFileList {
  width: 100%;
  display: flex;
  align-items: flex-end;
  margin-top: 20px;
  position: relative;
  width: 160px;
  max-width: 100%;
  margin-right: 15px;
  /* margin: 0px 10px; */
}

.mediaFileList ul img {
  object-fit: cover;
  border-radius: 10px;
  width: 160px;
  height: 160px;
  border: 1px solid #D9E0E6;
}

.mediaFileList ul li {
  list-style: none;
}

.mediaFileList .clearBackImage {
  /* width: 93px;
  height: 36px; */
  background: #FF5449;
  position: absolute;
  top: -5px;
  right: -30px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  /* border-radius: 4px; */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-left: 10px;
  cursor: pointer;
}

.card-title1 {
  display: initial !important;
}

.pro-hover11 {
  position: fixed;
  bottom: 50px;
  left: 0px;
  width: 264px;
}

.react-confirm-alert-overlay {
  background-color: #5E6A82B2 !important;
}

.react-confirm-alert-body {
  text-align: center !important;
  width: 480px !important;
  height: 250px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 16px !important;
}

.react-confirm-alert-body>h1 {
  font-weight: bold;
  text-align: center;
  margin: 10px 0px !important;
}

.react-confirm-alert-button-group {
  align-items: center;
  justify-content: center !important;
  margin-top: 30px !important;
}

.react-confirm-alert-button-group>button {
  padding: 0.625em 1.1em !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  transition: box-shadow .1s !important;
  box-shadow: 0 0 0 3px transparent !important;
}

.pc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(0deg, #F3F6F8, #F3F6F8),
    linear-gradient(0deg, #D9E0E6, #D9E0E6);
  border: 1px solid #D9E0E6;
  padding: 6px 30px 6px 30px;
  margin-bottom: 10px;
  height: 64px;
  border-radius: 6px;
}

.pc p {
  margin-bottom: 0;
}

.pc1 {
  display: flex;
  align-items: center;
}

.pc1 p {
  color: #181C32;
  font-weight: 500;
  font-size: 15px;
}

.parent_component {
  width: 100%;
}

.child_component {
  width: 92%;
  margin: 10px 0px;
}

.parents_component {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.add-component {
  margin-top: 10px;
}

.add-component button {
  border: 1px solid #205A95;
  background: #F3F6F8;
  padding: 12px 16px 12px 14px;
  border-radius: 6px;
  color: #205A95;
  font-weight: 600;
  width: 191px;
  font-size: 14px;
  height: 44px;
}

.cd-top {
  background: linear-gradient(0deg, #F3F6F8, #F3F6F8),
    linear-gradient(0deg, #D9E0E6, #D9E0E6);
  border-left: 1px solid #D9E0E6;
  padding: 2rem;
}

.cd-top p {
  color: #3595F6;
}

.cd-mid {
  padding: 2rem;
  margin-top: 1rem;
}

.cd-mid p {
  margin-bottom: 3rem;
}

.cd-main {
  position: relative;
}

.cd-bottom {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  bottom: 0px;
  padding: 1rem;
  background: linear-gradient(0deg, #F3F6F8, #F3F6F8),
    linear-gradient(0deg, #D9E0E6, #D9E0E6);
  border-left: 1px solid #D9E0E6;
}

.cd-bottom button {
  border: 1px solid #205A95;
  background: #F3F6F8;
  padding: 12px 16px 12px 14px;
  border-radius: 6px;
  color: #205A95;
  font-weight: 700;
}

.kt_app_footer {
  position: fixed !important;
  bottom: 0px !important;
  left: 0px !important;
  right: 0px !important;
}

.tiger_img {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  right: 25px;
}

.subComponent {
  color: #181C32;
  font-size: 15px;
  font-weight: 400;
  opacity: .7;
  margin-left: 10px;
}

.card-header11 {
  background-color: #F3F6F8 !important;
  border-bottom: 1px solid #D9E0E6 !important;
}

.card-header11 h2 {
  color: #3595F6;
  font-size: 17px !important;
  font-weight: 500 !important;
}

.card-body11 label {
  color: #3F4254;
  font-weight: 500;
  font-size: 15px;
}

.card-body11 input {
  border: 1px solid #D9E0E6;
  border-radius: 6px;
  height: 54px;
  background-color: #F3F6F8;
  color: #181C32 !important;
  font-weight: 400;
  font-size: 15px;
}

.card-body11 input:hover:focus {
  border: 1px solid #3595F6 !important;
}

.section_user {
  width: 100%;
  display: flex;
}

.user_sect1 {
  width: 100%;
  height: 105px;
  border: 1px solid #E1E3EA;
  margin: 0px 15px;
  display: flex;
  align-items: center;
  border-radius: 12px;
  padding: 0px 20px;
  cursor: pointer;
  /* justify-content: space-evenly; */
}

.user_img {
  width: 65px;
  height: 65px;
  border-radius: 6px;
  border: 1px solid #E1E3EA !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user_headers {
  margin-left: 15px;
}

.user_sect1:hover {
  background-color: #EFF8FF;
  border: 1px solid #60B7FA;
}

.user_sect1:hover .user_img {
  background-color: #3595F6;
}

.user_sect1:hover h2 {
  color: #3595F6;
}

.user_sect1:hover span {
  color: #3595F6;
}

.hr_line {
  border-bottom: 1.5px solid #D9E0E6 !important;
  margin-left: -30px;
  margin-right: -30px;
}

.file_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #D9E0E6;
  height: 64px;
  border-radius: 6px;
  padding: 0px 22px;
}

.file_selectLeft span {
  color: #2A2E37;
  font-weight: 400;
  font-size: 15px;
  margin-left: 10px;
}

.processor {
  color: #0B0E1E;
  font-weight: 500;
  font-size: 18px;
}

.um-content222 {
  padding: 20px 20px;
}

.parents_compi {
  margin-top: 20px;
}

.Upload_enclous {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #D9E0E6;
  height: 64px;
  border-radius: 6px;
  padding: 0px 22px;
  margin-top: 15px;
}

.Upload_enclous_left span {
  /* color: #BFC8D1; */
  font-size: 15px;
  font-weight: 400;
  margin-left: 10px;
}

.upload_enclous_right {
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload_enclous_right svg {
  display: block;
  margin: auto;
}

.upload_enclous_right p {
  font-weight: 400;
  font-size: 15px;
  color: #2A2E37;
  margin-left: 10px;
  margin-top: 10px;
}

.Upload_enclous {
  position: relative;
}

.Upload_enclous input {
  position: absolute;
  opacity: 0;
  width: 100%;
}

.laoder {
  z-index: 9999999 !important;
  position: fixed;
  background: rgba(0, 0, 0, 0.234);
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user_son {
  font-size: 28px !important;
  font-weight: bold !important;
  color: #0B0E1E !important;
}

.user_profil {
  padding: 20px 0px;
  box-shadow: 0px 1px 2px 0px #151E2814;
  box-shadow: 0px 2px 4px 0px #0D172114;
  border-radius: 8px;
  background-color: #ffffff;
}

.header_profile {
  padding: 15px 20px;
  border-bottom: 1px solid #E2E8F0;
}

.rafiq_header {
  margin-left: 15px;
  margin-top: 10px;
}

.rafiq_header h2 {
  font-weight: 600;
  font-size: 20px;
  color: #1E293B;
  line-height: 24.2px;
}

.rafiq_header p {
  color: #475569;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.rafiql {
  padding: 22px 20px;
  border-bottom: 1px solid #E2E8F0;
}

.person_information {
  padding: 25px 20px;
  border-bottom: 1px solid #E2E8F0;
  background-color: #ffffff;
}

.person_information h2 {
  font-size: 16px;
  color: #1E293B;
  font-weight: 600;
}

.first_name span {
  color: #475569;
  font-weight: 400;
  font-size: 14px;
}

.first_name p {
  color: #161B22;
  font-weight: 600;
  font-size: 14px;
  margin-top: 5px;
}

.last_name span {
  color: #475569;
  font-weight: 400;
  font-size: 14px;
}

.last_name p {
  color: #161B22;
  font-weight: 600;
  font-size: 14px;
  margin-top: 5px;
}

.bio h3 {
  font-weight: 500;
  font-size: 12px;
  color: #1E293B;
}

.bio p {
  color: #475569;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.address {
  padding: 20px;
}

.reset_pass_det {
  padding: 20px;
}

.reset_pass_det h2 {
  font-size: 16px;
  color: #1E293B;
  font-weight: 600;
}

.address h2 {
  font-size: 16px;
  color: #1E293B;
  font-weight: 600;
}

.download_ser {
  width: 127px;
  height: 40px;
  border-radius: 10px;
  background-color: #3595F6;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.download_ser1 {
  width: 148px;
}

.download_ser span {
  color: #ffffff;
  font-weight: 400;
  font-size: 15px;
}

.sendel {
  color: #3595F6;
}

.treats {
  background: linear-gradient(0deg, #F1F5F9, #F1F5F9),
    linear-gradient(0deg, #E2E8F0, #E2E8F0);

}

.odd {
  color: #3595F6 !important;
  font-weight: 400;
  font-size: 16px !important;
}

.tickna {
  color: #1E293B;
  font-weight: 500;
  font-size: 14px;
}

.tickna1 p {
  color: #0F172A;
  font-weight: 400;
  font-size: 16px;
}

.odie {
  color: #0F172A;
  font-weight: 500;
  font-size: 16px;
}

.odd1 {
  color: #475569;
  font-size: 14px;
  font-weight: 400;
}

.fory {
  font-weight: 500 !important;
  color: #1E293B !important;
  font-size: 14px !important;
}

.counter_header {
  padding: 10px 0px;
  border-bottom: 1px solid #D9E0E6;
}

.counter_header h2 {
  color: #1E293B;
  font-weight: 600;
  font-size: 16px;
}

.counter_input {
  margin: 20px 0px;
}

.counter_input label {
  font-weight: 400;
  font-size: 15px;
  color: #3F4254;
}

.counter_input input {
  width: 100%;
  height: 64px;
  border: 1px solid #D9E0E6;
  color: #1E293B;
  font-weight: 500;
  font-size: 16px;
}

.counter_input input::placeholder {
  color: #1E293B;
  font-weight: 500;
  font-size: 16px;
}

.can_save_btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.cancel button {
  width: 93px;
  height: 48px;
  border: 1px solid #7E8299;
  background: linear-gradient(0deg, #F3F6F8, #F3F6F8),
    linear-gradient(0deg, #7E8299, #7E8299);
  color: #3F4254;
  font-weight: 500;
  font-size: 16px;
  border-radius: 6px;

}

.save button {
  width: 106px;
  height: 48px;
  background-color: #3595F6;
  border: none;
  border-radius: 6px;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 16px;
  margin-left: 20px;
}

.save button span {
  margin-left: 5px;
}


/* ====================landing page css============ */
.t_navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 60px;
  height: 60px;
  max-height: 100%;
  box-shadow: 0px 1px 0px 0px #00000013;
}

.reg_page {
  display: flex;
  align-items: center;
}

.nav_logo img {
  max-width: 120px;
  width: 100%;
  object-fit: cover;
}

.sign_ip button {
  width: 74.75px;
  height: 36px;
  border-radius: 8px;
  background: #3595F6;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  border: none;
  outline: none;
  cursor: pointer;
  margin-left: 15px;
}

.signin span {
  color: #161B22;
  font-weight: 400;
  font-size: 15px;
}

.home-navbar {
  max-width: 1480px;
  display: block;
  margin: auto;
}

.home-main {
  max-width: 1480px;
  display: block;
  margin: auto;
  padding-bottom: 60px;
}

.home_banner {
  display: flex;
  align-items: center;
  justify-content: center;
  /* justify-content: space-between; */
  padding: 0px 60px;
  padding-top: 40px;
  padding-bottom: 60px;
}

.home_banner_left {
  /* width: 50%; */
  margin-right: 20px;
}

.home_banner_right {
  /* width: 50%; */
  margin-left: 20px;
}
.gt_st{
  display: block;
  margin: auto;
}
.home_banner_left h1 {
  color: #161B22;
  font-size: 50px; /* pehle 70 tha */
  font-weight: bold;
  /* white-space: nowrap; */
  line-height: 75px;
  text-align: center;
  /* width: 8em;
  max-width: 100%; */
}

.head_btn button {
  max-width: 312px;
  width: 100%;
  height: 74px;
  box-shadow: 0px 0px 0px 1px #D8D8DA inset;
  border: 1px solid #3595F6;
  color: #3595F6;
  font-weight: 500;
  font-size: 20px;
  border-radius: 10px;
  padding: 14px, 26px, 14px, 26px;
  margin-top: 30px;
  background-color: #FFFFFF;
}

.dolibo_welcome {
  max-width: 1480px;
  display: block;
  margin: auto;
}

.dolibo_welcome_page {
  padding: 60px 60px;
}

.welcome_heading h2 {
  color: #161B22;
  font-size: 36px;
  font-weight: bold;
}

.welcome_heading span {
  color: #3595F6;
}

.welcome_heading p {
  font-size: 24px;
  color: #161B22;
  font-weight: 400;
}

.welcone_card {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.welcone_box {
  /* max-width: 599px; */
  width: 100%;
  height: 473px;
  max-height: 100%;
  background: linear-gradient(180deg, #ECFBF8 0%, #E5FCDE 100%);
  padding: 60px, 40px, 60px, 40px;
  border-radius: 14px;
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.welcone_box1 {
  background: linear-gradient(180deg, #E5F4FF 0%, #CCE9FF 100%);

}

.box_paras {
  text-align: center;
}

.box_paras h3 {
  color: #161B22;
  font-weight: bold;
  font-size: 32px;
}

.box_paras p {
  color: #464853;
  font-weight: 400;
  font-size: 16px;
  width: 80%;
  display: block;
  margin: auto;
}

.welcone_card_btn button {
  max-width: 245px;
  width: 100%;
  height: 74px;
  max-height: 100%;
  border: 1px solid #3595F6;
  cursor: pointer;
  box-shadow: 0px 0px 0px 1px #D8D8DA inset;
  color: #3595F6;
  font-weight: 500;
  font-size: 20px;
  padding: 14px, 26px, 14px, 26px;
  border-radius: 10px;
  display: block;
  margin: 40px auto;
  background-color: #FFFFFF;
}

.acclearte {
  max-width: 1480px;
  display: block;
  margin: auto;
}

.accelarate_page {
  padding: 50px 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.accelarate_page h3 {
  font-weight: 700;
  font-size: 36px;
  color: #161B22;
  width: 80%;
  display: block;
  margin: auto;
  text-align: center;
}

.accelarate_page span {
  color: #3595F6;
  font-weight: 500;
}

.convent_code img {
  max-width: 1221px;
  width: 100%;
  display: block;
  margin: auto;
  margin-top: 40px;
}

.footer {
  background-color: #F5F6F7;
}

.fite {
  padding: 0px 60px;
  padding-top: 80px;
  padding-bottom: 100px;

  max-width: 1480px;
  display: block;
  margin: auto;
}

.footer_logo img {
  display: block;
  margin: auto;
}

.footer_menu {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 100px;
}

.footer_link {
  display: flex;
  align-items: center;
}

.footer_link p {
  margin: 0px 30px;
  color: #231F20;
  font-size: 18px;
  font-weight: 400;
}

.footer_redirect {
  text-align: center;
  margin-top: 30px;
}

.footer_redirect p {
  color: #3595F6;
  font-weight: 400;
  font-size: 20px;
  border-bottom: 1px solid #3595F6;
  display: inline;
}

.footer_social_link {
  display: flex;
  align-items: center;
}

.footer_social_link img {
  margin: 0px 15px;
}

.hr {
  margin-top: 60px;
}

.hr hr {
  max-width: 1172px;
  width: 100%;
  display: block;
  margin: auto;
}

.hr p {
  color: #231F20;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  margin-top: 30px;
}

.accl1 {
  padding-bottom: 85px;
}

.img_banner img {
  /* max-width: 741px; */
  width: 100%;
  object-fit: cover;
}

/* ================signin page css============== */
.sigin {
  height: 100vh;
  padding: 50px 80px;
  /* padding-top: 50px; */
}

.signin_cards {
  position: relative;
  padding-bottom: 50px;
  padding-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 50px; */
  /* height: 75vh; */
}

.svgd {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.sigin_card {
  max-width: 503px;
  width: 100%;
  object-fit: cover;
  /* height: 574px; */
  border-radius: 12px;
  border: 1px solid #E3E6EB;
  padding: 50px;
  background-color: #ffffff;
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%); */
}

.sigin_card form {
  width: 100%;
  margin-top: 40px;
}

.signin_head h2 {
  color: #161B22;
  font-weight: 400;
  font-size: 40px;
  text-align: center;
}

.signin_head p {
  color: #74788D;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  margin-top: 5px;
}

.signin_input {
  width: 100%;
}

.signin_input input {
  width: 100%;
  border: 1px solid #82868C;
  padding: 18px, 20px, 18px, 20px;
  height: 60px;
  border-radius: 10px;
  color: #82868C;
  font-weight: 400;
  font-size: 15px;
  outline: none;
  margin: 20px 0px;
}

.signin_input input::placeholder {
  color: #82868C;
  font-weight: 400;
  font-size: 15px;
}

.signin_btn button {
  width: 100%;
  background-color: #3595F6;
  height: 60px;
  max-height: 100%;
  padding: 18px, 20px, 18px, 20px;
  border-radius: 10px;
  color: #FFFFFF;
  font-weight: bold;
  border: none;
  cursor: pointer;
  outline: none;
  font-size: 20px;
  margin-top: 30px;
}

.signin_hr hr {
  border-bottom: 1px solid #E3E6EB;
  width: 100%;
  margin-top: 40px;
}

.forgot_pass {
  margin-top: 30px;
}

.forgot_pass p {
  color: #181C32;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  line-height: 16px;
}

.forgot_pass span {
  color: #3595F6;
}

.otp_screen {
  display: flex;
  align-items: center;
  justify-content: center;
}

.otp_screen .signin_input {
  margin-right: 10px;
}

.otp_screen input {
  height: 74px;
  max-height: 100%;
  border: 1px solid #82868C !important;
  color: #1D212E;
  font-weight: bold;
  font-size: 40px;
  text-align: center;
}

.otp_screen input::placeholder {
  color: #1D212E;
  font-weight: bold;
  font-size: 40px;
  text-align: center;
}

.signin_head span {
  color: #161B22;
  text-decoration: underline;
}

.verify_again {
  margin-top: 30px;
}

.verify_again p {
  text-align: center;
  color: #74788D;
  font-weight: 400;
  font-size: 16px;
}

.verify_again span {
  color: #161B22;
  text-decoration: underline;
}

.dropdowns_menu {
  width: 219px;
  height: 64px;
  padding: 10px;
  border-radius: 8px;
  gap: 8px;
  box-shadow: 0px 6px 24px 0px #0000000D !important;
  border: 1px solid #D9E0E6 !important;
  /* display: flex !important; */
  background-color: #FFFFFF !important;
}

.drp_menu {
  height: auto;
  max-width: 223px;
  width: 100%;
  /* max-height: 100%; */
}

.view_details {
  display: flex;
  align-items: center;
  padding: 10px 0px;
  /* border-bottom: 1px solid #D9E0E6; */
  margin: 0px 15px;
}

.view_details1 {
  border-bottom: none;
}

.home_banner1 {
  padding: 0px 0px !important;
}

.dolibo_welcome_page1 {
  padding: 60px 0px !important;
}

.box_btn button {
  width: 248px;
  max-width: 100%;
  height: 74px;
  max-height: 100%;
  border-radius: 10px;
  border: 1px solid #3595F6;
  padding: 14px, 26px, 14px, 26px;
  color: #3595F6;
  font-weight: 500;
  font-size: 20px;
  text-transform: uppercase;
  margin-top: 20px;
}

.user_end_btn button {
  width: 97px;
  height: 36px;
  border-radius: 29px;
  border: 1px solid #3595F6;
  background-color: #E4F3FF;
  gap: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user_end_btn button span {
  color: #3595F6;
  font-weight: 400;
  font-size: 15px;
}

.head_btn1 button {
  background-color: #3595F6;
  color: #FFFFFF;
  box-shadow: 0px 0px 0px 1px #D8D8DA inset;
  border: none;
}

.user_profiling {
  padding: 0px 0px !important;
  margin-bottom: 30px;
}

.peru {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.peru button {
  border: 1px solid #475569;
  width: 95px;
  height: 40px;
  border-radius: 29px;
  padding: 8px, 20px, 8px, 16px;
  gap: 8px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

.peru button span {
  color: #475569;
  font-weight: 400;
  font-size: 16px;
}

.modal-header1 {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end !important;
}

.modal_download h2 {
  color: #161B22;
  font-weight: bold;
  font-size: 28px;
}

.modal_file {
  /* max-width: 419px; */
  width: 100%;
  height: 57px;
  max-height: 100%;
  border-radius: 6px;
  border: 1px solid #60B7FA;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
  margin: 20px 0px;
}

.modal_file button {
  width: 156px;
  max-width: 100%;
  height: 40px;
  background-color: #3595F6;
  border: none;
  padding: 8px, 15px, 8px, 15px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.modal_file button span {
  color: #FFFFFF;
  font-weight: 400;
  font-size: 15px;
}

.modal_content1 {
  max-width: 539px !important;
  width: 100% !important;
}

.download_all {
  width: 176px;
  height: 40px;
  border-radius: 10px;
  padding: 8px, 15px, 8px, 15px;
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3595F6;
  border: none;
}

.download_all span {
  color: #FFFFFF;
  font-weight: 400;
  font-size: 15px;
}

.modal-footer1 {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.rafiq_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.rafiq_header button {
  border: 1px solid #475569;
  width: 95px;
  height: 40px;
  border-radius: 29px;
  padding: 8px, 20px, 8px, 16px;
  gap: 8px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rafiq_header button span {
  color: #475569;
  font-weight: 400;
  font-size: 16px;
}

.end_input {
  margin: 25px 0px;
}

.end_input input {
  width: 100%;
  max-height: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  border: 1px solid #D9E0E6;
  border-radius: 6px;
  color: #161B22;
  font-weight: 400;
  font-size: 15px;
  outline: none;
  margin-top: 4px;
}

/* .end_input input::placeholder{
  color: #161B22;
  font-weight: 400;
  font-size: 15px;
} */
.end_input label {
  color: #3F4254;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
}

.update_end_profile {
  padding: 20px 0px;
}

.update_end_profile button {
  display: block;
  margin: auto;
}

.doat {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.doat input {
  max-width: 282px;
  width: 100%;
  height: 42px;
  max-height: 100%;
  border: 1px solid #DFE3E8;
  outline: none;
  padding: 0px 20px;
  border-radius: 6px;
  color: #919EAB;
  font-weight: 500;
  font-size: 14px;
}

.masting_cards {
  cursor: pointer;
}

.dropdowns_menu span {
  margin-left: 10px;
}

.dropdowns_menu svg {
  margin-top: -4px;
}

.dt_menu {
  height: auto;
}

.search_user input {
  width: 282px;
  max-width: 100%;
  height: 42px;
  max-height: 100%;
  border: 1px solid #DFE3E8;
  border-radius: 6px;
  padding: 12px, 20px, 12px, 20px;
  outline: none;
}

.counter_card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 12px;
}

.counter_box {
  /* background: linear-gradient(180deg, #ECFBF8 0%, #E5FCDE 100%); */
  box-shadow: 0px 4px 8px 0px #00000026;
  background-color: #FFFFFF;
  /* height: 266.91px; */
  height: 285px;
  border-radius: 8px;
  padding: 0px 15px;
  margin: 0px 0px;
  width: 32%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
.btn_dwnl button{
  padding: 6px 18px;
  font-weight: bold;
  border-radius: 5px;
}
.btn_dwnl button:hover{
  background-color: #0bc5ea;
  transition: all .5s;
  color: white;
  border: none;

}
.yt_pring{
  display: flex;
  align-items: center;
}
.d_count{
  position: absolute;
  top: 8px;
  right: 8px;
}
/* .counter_box1{
  background: linear-gradient(180deg, #E5F4FF 0%, #CCE9FF 100%);
}
.counter_box2{
  background: linear-gradient(180deg, #ECFBF8 0%, #E5FCDE 100%);
} */

.cant2 {
  text-align: center;
}

.react-form-builder-form .rfb-item label span {
  color: #3F4254 !important;
  font-weight: 400 !important;
  font-size: 15px !important;
}

.SortableItem input[type="text"],
.SortableItem input [type="email"],
.SortableItem input[type="number"] {
  height: 64px !important;
  max-height: 100% !important;
  border-radius: 6px !important;
  border: 1px solid #D9E0E6 !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  color: #1E293B !important;
}

.SortableItem select {
  height: 64px !important;
  max-height: 100% !important;
  border-radius: 6px !important;
  border: 1px solid #D9E0E6 !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  color: #1E293B !important;
}
.static{
    position: absolute;
    left: 10px;
    font-size: 16px !important;
    font-weight: 600 !important;
    top: 3.5px;
}
.btn-toolbar {
  justify-content: flex-end !important;
}

.custom-radio label {
  margin-left: 8px !important;
  color: #3F4254 !important;
  font-weight: 400 !important;
  font-size: 15px !important;
}

.custom-checkbox label {
  margin-left: 8px !important;
  color: #3F4254 !important;
  font-weight: 400 !important;
  font-size: 15px !important;
}

.btn-toolbar input {
  background-color: #3595F6;
  color: #FFFFFF;
  font-weight: 500 !important;
  font-size: 16px !important;
}

.cant2 h3 {
  color: #161B22;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
}

.cant2 p {
  text-align: center;
  color: #464853;
  font-weight: 400;
  font-size: 9px;
}

.cant3 button {
  width: 140px;
  height: 41px;
  border-radius: 6px;
  border: 1px solid #3595F6;
  /* box-shadow: 0px 0px 0px 0.5642915964126587px #D8D8DA inset; */
  box-shadow: 0px 0px 0px 0.5642915964126587px #D8D8DA inset;
  background-color: #FFFFFF;
  padding: 8px, 15px, 8px, 15px;
  color: #3595F6;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
}
.cant3 button:hover{
  background-color: #3595F6;
  color: white;
  transition: .3s;
  border: none;
}
.pupmkin {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #DB6C56;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #E9ECEF66;
}

.pupmkin span {
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 500;
}

/* .float-left{
  float: left;
}
.float-right{
  float: right;
} */
.react-form-builder>div:first-child {
  display: flex;
  flex-direction: row;
}

.react-form-builder .react-form-builder-preview {
  width: 75% !important;
}

.react-form-builder .react-form-builder-toolbar {
  width: 25% !important;
  z-index: initial !important;
}

.clearfix .modal-content {
  position: relative !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  width: 100% !important;
  pointer-events: auto !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid rgba(0, 0, 0, .2) !important;
  border-radius: 0.3rem !important;
  outline: 0 !important;
  padding: 30px !important;
  max-height: 800px !important;
  overflow-y: auto !important;
}

.clearfix .custom-control {
  position: relative !important;
  display: block !important;
  min-height: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.btn1 {
  margin: 0px 8px;
}

.btn2 {
  margin: 0px 8px;
  font-size: 15px;
}

.btn3 {
  margin: 0px 8px;
}

.catalog_box {
  width: 100%;
  background-color: #F1F5F9;
  border-radius: 12px;
  height: 198px;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0px 20px;
}

.catalog_box_img img {
  width: 100%;
  object-fit: cover;
}

.cata_lok {
  margin: 10px;
}

.detr h3 {
  color: #161B22;
  font-weight: bold;
  font-size: 24px;
}

.detr span {
  color: #3595F6;
  font-weight: 400;
  font-size: 16px;
}

.detr p {
  color: #464853;
  font-weight: 400;
  font-size: 16px;
  margin-top: 20px;
}

.detr_sus {
  margin: 0px 30px;
}

.cata_btn button {
  width: 187px;
  height: 62px;
  border-radius: 6px;
  border: 1px solid #3595F6;
  box-shadow: 0px 0px 0px 0.5642915964126587px #D8D8DA inset;
  padding: 18px, 20px, 18px, 20px;
  cursor: pointer;
  color: #3595F6;
  font-weight: 500;
  font-size: 15px;
  background-color: #FFFFFF;
  margin-top: 30px;
}
.cata_btn button:hover{
  background-color: #3595F6;
  transition: .5s;
  color: white;
  border: none;
}
.btn-toolbar input:hover {
  background-color: #3491ef;
  color: #ffffff;
}
.rafg{
  width: 64px;
  height: 64px;
  border-radius: 50%;
  object-fit: cover;
}
.rafg1{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}
.table_resp1{
  height: 100vh;
}
.rafiq_img img{
  width: 64px;
  height: 64px;
  border-radius: 50%;
  object-fit: cover;
}
.select_filet{
  height: 45px;
  width: 300px !important;
  border: none;
}
.conts{
  width: 100% !important;
  height: 64px !important;
  margin-top: 30px;
}
/* #optionsApiUrl{
  display: none !important;
} */
/* .edit-form .form-group:nth-child(3){
  display: none !important;
} */

.signin_btn button:hover{
  background-color: #1f82de;
}

.com-d-flag{
  display: none !important;
}

.modal-backdrop:not(#_) ~ .modal-backdrop,
body:not(.modal-open) .modal-backdrop {
  display: none !important;
 
}

.modal-backdrop {
  z-index: -1;
}
.rapid_fetch{
  margin-top: 20px;
}
.rapid_fetch span{
  font-size: 18px;
  font-weight: 500;
}
.rapid_fetch input{
  width: 100% !important;
  height: 64px !important;
  max-width: 360px;
  border: 1px solid #D9E0E6 !important ;
  outline: none !important;
  margin-left: 25px;
  padding: 0px 15px;
  border-radius: 6px;
}
.rapid_div{
  margin-left: 30px;
}
.testing_rapid{
  margin-left: 10px;
}
.rapid_div p{
  line-height: 15px;
}
.rapid_div span{
  font-size: 16px;
  font-weight: 500;
  /* line-height: 7px; */
}
.rapid_img{
  width: 8rem;
  height: 8rem;
}
.rapid_fetch button{
  padding: 0px 22px;
  height: 64px;
  background-color: #3595F6 !important;
  border: none !important;
  outline: none !important;
  color: white !important;
  font-size: 16px;
  font-weight: 500;
  margin-left: 10px;
  border-radius: 6px;
}
.rapid_fetch{
  display: flex;
  align-items: center;
}
.rapid_div{
  display: flex;
  align-items: center;
}
.dolibo_button{
  display:flex;
  align-items: center;
  cursor: pointer;
}
.dolibo_button1{
  display:flex;
  align-items: center;
  margin-top: 15px;
}
.css_trigt{
    width: 50px;
    height: 50px;
    background-color: rgb(238, 238, 238);
    border-width: 4px;
    border-radius: 10px;
    border: 3px solid rgb(98, 98, 98);
}
.css_trigt img{
  width: 100%;
  padding: 1rem;
}
.dolibo_button p{
  color: #000000;
  font-size: 20px;
  margin-top: 10px;
  margin-left: 15px;
  font-weight: 600;
}
.dolibo_button1 p{
  color: #000000;
  font-size: 20px;
  margin-top: 10px;
  margin-left: 15px;
  font-weight: 600;
}
.code_modal{
  height: 80vh;
  width: 100% !important;
  max-width: 1300px !important;
}
.code_header{
  background-color: #009ef7;
}
.code_title11{
  font-size: 1.6rem;
  color: white;
}
.code_dialog{
  max-width: 1300px !important;
  width: 100% !important;
  padding: 0px 20px !important;
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%) !important;
}
.svg_teen{
  fill: #ffffff !important;
}
.button_clipboard button{
  padding: 6px 18px;
  border-radius: 5px;
}
.button_clipboard button:hover{
  background-color: #0bc5ea;
  transition: .5s;
  color: white;
  border: none;
}
.modal-body_code{
  margin: 20px;
  background-color: rgb(245, 242, 240);
  overflow-y: scroll;
}
.t_img{
  width: 100% !important;
}

.wire_dialog{
  max-width: 1300px !important;
  width: 100% !important;
  margin: 0px 20px !important;
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%) !important;
}
.wire_header{
  background-color: #009ef7;
}
.wire_title{
  font-size: 1.6rem;
  color: white;
}
.wire_content{
  height: 80vh;
  max-height: 100%;
}
.diagram_body{
  margin: 20px;
  overflow-y: scroll;
}
.diagram_body img{
  width: 100%;
}
@media only screen and (max-width:1286px) {
  .counter_box {
    width: 45%;
  }
}

@media only screen and (max-width:1100px) {
  .home_banner_left h1 {
    font-size: 58px;
    line-height: 65px;
  }
}

@media only screen and (max-width:990px) {
  .home_banner {
    flex-direction: column-reverse;
    align-items: center;
  }

  .home_banner_right {
    width: 100%;
  }

  .home_banner_left {
    width: 100%;
  }

  .img_banner img {
    width: 100%;
    max-width: 400px;
    display: block;
    margin: auto;
  }

  .home_banner_left h1 {
    text-align: center;
  }

  .head_btn button {
    display: block;
    margin: auto;
    margin-top: 30px;
  }

  .welcone_card {
    flex-direction: column;
  }

  .welcone_box {
    margin: 12px 0px;
  }

}

@media only screen and (max-width:764px) {
  .home_banner {
    padding: 0px 40px;
  }
  .rapid_div{
    margin-top: 15px;
    margin-left: 0px;
  }
  .home_banner_left h1 {
    font-size: 45px;
    line-height: 54px;
  }

  .head_btn button {
    font-size: 18px;
  }

  .box_btn button {
    font-size: 18px;
  }

  .welcone_card_btn button {
    font-size: 18px;
  }

  .dolibo_welcome_page {
    padding: 60px 40px;
    padding-top: 80px;
  }

  .welcome_heading {
    text-align: center;
  }

  .welcone_box {
    padding: 25px;
    height: auto;
  }

  .accelarate_page {
    padding: 50px 40px;
  }

  .accelarate_page h3 {
    width: 100%;
  }

  .fite {
    padding: 0px 40px;
  }

  .t_navbar {
    padding: 0px 40px;
  }

  .footer_menu {
    flex-direction: column;
    margin-top: 50px;
  }

  .footer_social_link {
    margin-top: 30px;
  }

  .sigin {
    padding: 50px 40px;
  }

  .counter_box {
    width: 100%;
    height: auto;
    padding: 20px 15px;
  }

  .catalog_box {
    margin: 12px 0px;
  }

  .detr_sus {
    margin: 0px 0px;
  }

  .cata_lok {
    margin: 0px !important;
  }
  .section_user{
    flex-direction: column;
  }
  .user_sect1{
    margin: 12px 0px;
  }
  .rapid_fetch{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .rapid_fetch input{
    margin-left: 0px;
    width: 100% !important;
    max-width: 100% !important;
    margin-top: 10px;
  }
  .rapid_fetch button{
    margin-left: 0px;
    margin-top: 10px;
  }
}
@media only screen and (max-width:712px){
  .modal-header{
    flex-wrap: wrap;
  }
  .button_clipboard{
    margin-top: 15px;
  }
  .btn_dwnl{
    margin-top: 15px;
  }
  .sharef{
    margin-top: 15px;
  }
}
@media only screen and (max-width:500px){
  .t_active{
    width: 100% !important;
  }
  .pre_ren{
    overflow-x: scroll !important;
    padding: 0px 10px;
  }
}
@media only screen and (max-width:450px) {
  .home_banner {
    padding: 20px;
  }
  .search_user input{
    width: 100%;
    max-width: 100%;
  }
  .suching th,
  .suching td{
    white-space: nowrap;
  }
  .form_search input{
    width: 100% !important;
    max-width: 100%;
  }
  .tri11 td{
    white-space: nowrap ;
  }
  .action_box{
    right: 0px;
  }
  .home_banner_left h1 {
    font-size: 35px;
    line-height: 40px;
    margin-top: 10px;
  }

  .dolibo_welcome_page {
    padding: 60px 20px;
  }

  .welcome_heading h2 {
    font-size: 30px;
  }

  .welcome_heading p {
    font-size: 20px;
  }

  .box_paras h3 {
    font-size: 30px;
  }

  .box_paras p {
    width: 100%;
  }

  .accelarate_page {
    padding: 50px 20px;
  }

  .accelarate_page h3 {
    width: 100%;
    font-size: 30px;
  }

  .fite {
    padding: 0px 20px;
  }

  .t_navbar {
    padding: 0px 20px;
  }

  .footer_link {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .footer_link p {
    font-size: 16px;
  }

  .counter_img img {
    width: 100%;
  }

  .sigin {
    padding: 50px 20px;
  }

  .sigin_card {
    padding: 50px 20px;
  }

  .signin_head h2 {
    font-size: 30px;
  }

  .forgot_pass p {
    font-size: 14px;
  }
  .ql-snow.ql-toolbar button, .ql-snow .ql-toolbar button{
    height: 18px !important;
    width: 18px !important;
  }
  .reg_page {
    margin-top: 20px;
  }
  .react-form-builder>div:first-child{
    flex-direction: column-reverse !important;
    align-items: center !important;
  }
  .react-form-builder .react-form-builder-toolbar{
    width: 100% !important;
    margin-top: 0px !important;
    height: 300px !important;
  }
  .react-form-builder .react-form-builder-preview{
    width: 100% !important;
  }
  .Upload_enclous_left{
    display: flex;
    align-items: center;
  }
  .Upload_enclous_left span{
    font-size: 12px;
  }
  .Upload_enclous_left svg{
    width: 20px !important;
    height: 20px !important;
  }
  .delete_svg svg{
    width: 16px;
    height: 16px;
  }
  .react-form-builder .react-form-builder-preview{
    margin-top: 40px;
  }
}

@media only screen and (max-width:320px) {
  .t_navbar {
    flex-direction: column;
    height: auto;
    padding: 20px;
  }
}